import React, { useContext, useEffect, useState } from "react";
import {
  get_consultant_data_localStorage,
  get_project_info_localStorage,
  get_login_info_localStorage,
  _get_admin_time_zone_localStorage,
  _get_content_setting_localStorage,
  _get_home_event_localStorage,
  _get_navbar_list_localStorage,
  _get_testmonial_localStorage,
  _get_user_from_localStorage,
  get_login_page_localStorage,
  _get_client_graphics_from_localStorage,
  set_client_graphics_in_localStorage,
  _get_client_meta_data_from_localStorage,
} from "src/DAL/localStorage/localStorage";

import io from "socket.io-client";
import { socketBaseUri } from "src/config/config";
import { get_date_with_user_time_zone } from "src/utils/constants";
import moment from "moment";
const CreateContentSetting = React.createContext();
const content_setting = _get_content_setting_localStorage();
const get_testmonial_localStorage = _get_testmonial_localStorage();
const get_home_event_localStorage = _get_home_event_localStorage();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_project_info = get_project_info_localStorage();
const get_login_info = get_login_page_localStorage();
const _get_user_info = _get_user_from_localStorage();
const _get_consultant_data = get_consultant_data_localStorage();
const get_navbar_list = _get_navbar_list_localStorage();
const client_graphics = _get_client_graphics_from_localStorage();

const client_meta_data = _get_client_meta_data_from_localStorage();

// let socket = { off: () => {}, emit: () => {}, on: () => {} };
// socket-initialization
const socketConfig = {
  reconnectionDelayMax: 10000,
};
let socket = { on: () => {}, emit: () => {} };
// toturn off the socket
socket = io(socketBaseUri + `?user_id=${_get_user_info._id}`, socketConfig);
//----------------------
export const useContentSetting = () => useContext(CreateContentSetting);
function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |-------------------- 
  ------------------------------------------------------- */

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );
  const [contentSettingData, setContentSettingData] = useState(content_setting);
  const [jsCode, setJSCode] = useState();
  const [cssCode, setCssCode] = useState();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [feedSetting, setFeedSetting] = useState(null);
  const [stripeKey, setStripeKey] = useState("");
  const [projectInfo, setProjectInfo] = useState(get_project_info);
  const [loginInfo, setLoginInfo] = useState(get_login_info);
  const [isChangingCoach, setIsChangingCoach] = useState(false);
  const [userInfo, setUserInfo] = useState(_get_user_info);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [consultantInfo, setConsultantInfo] = useState(_get_consultant_data);
  const [consultantList, setConsultantList] = useState([]);

  const [navbarList, setNavbarList] = useState(get_navbar_list);

  const [dashboardEventData, setDashboardEventData] = useState(
    get_home_event_localStorage
  );
  const [dashboardTestimonialData, setDashboardTestimonialData] = useState(
    get_testmonial_localStorage
  );
  const [dashboardSettingData, setDashboardSettingData] = useState("");
  const [graphicsData, setGraphicsData] = useState(client_graphics);

  // --------------

  const [clientMetaData, setClientMetaData] = useState(client_meta_data);
  const [currentLanguage, setCurrentLanguage] = useState(null);

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleContentSettingData = (val) => {
    setContentSettingData(val);
  };
  const handleJSCode = (val) => {
    console.log(val, "val");
    setJSCode(val);
  };
  const handleCSSCode = (val) => {
    console.log(val, "val");

    setCssCode(val);
  };
  const handleDashboardSettingData = (val) => {
    setDashboardSettingData(val);
  };
  const handleDashboardTestimonialData = (val) => {
    setDashboardTestimonialData(val);
  };
  const handleDashboardEventData = (val) => {
    setDashboardEventData(val);
  };
  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };
  const handleUserInfo = (val) => {
    setUserInfo(val);
  };
  const handleConsultantInfo = (val) => {
    console.log(val, "avhasouivhasdiovhasvosi");
    setConsultantInfo(val);
  };

  const handleConsultantList = (val) => {
    setConsultantList(val);
  };
  const handleNavbarList = (val) => {
    setNavbarList(val);
  };

  const handleClientMetadata = (val) => {
    setClientMetaData(val);
  };
  const handleProjectInfo = (val) => {
    console.log(val, "projectinfo");
    setProjectInfo(val);
    localStorage.setItem(`project_info`, JSON.stringify(val));
    //
  };
  const handleLoginInfo = (val) => {
    setLoginInfo(val);
  };
  const handleStripeKey = (val) => {
    setStripeKey(val);
  };

  const handleGraphics = (val) => {
    set_client_graphics_in_localStorage(val);
    setGraphicsData(val);
  };

  const handleNotificationsCount = (val) => {
    setNotificationsCount(val);
  };

  const handleUnreadMessageCount = (val) => {
    setUnreadMessageCount(val);
  };

  const handleFeedSetting = (val) => {
    setFeedSetting(val);
  };

  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(
      socketBaseUri + `?user_id=${user_id}&role=member`,
      socketConfig
    );
  };
  const STOP_SOCKET_FORCEFULLY = (user_id) => {
    socket.disconnect();
  };

  useEffect(() => {
    if (userInfo?._id && !isConnected) {
      socket.emit("live_event_room", userInfo._id);
      setIsConnected(true);
    }
    return () => {};
  }, [userInfo, isConnected]);

  const convertedTimeInUserTimeZoneFromNow = (dateString) => {
    return moment(
      get_date_with_user_time_zone(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        userInfo.time_zone,
        adminTimeZone
      )
    ).fromNow();
  };

  const convertedTimeInUserTimeZoneFromCalender = (dateString) => {
    return moment(
      get_date_with_user_time_zone(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        userInfo.time_zone,
        adminTimeZone
      )
    ).calendar();
  };

  console.log(socket, "i am socket");
  const collection = {
    adminTimeZone,
    cssCode,
    jsCode,
    navbarList,
    setIsChangingCoach,
    isChangingCoach,
    consultantInfo,
    consultantList,
    userInfo,
    projectInfo,
    loginInfo,
    dashboardEventData,
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    graphicsData,
    currentLanguage,
    setCurrentLanguage,

    notificationsCount,
    unreadMessageCount,
    stripeKey,
    feedSetting,
    handleContentSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    handleGraphics,
    handleClientMetadata,
    handleAdminTimeZone,
    convertedTimeInUserTimeZoneFromNow,
    convertedTimeInUserTimeZoneFromCalender,
    handleUserInfo,
    handleConsultantInfo,
    handleConsultantList,
    handleNavbarList,
    handleProjectInfo,
    handleLoginInfo,
    handleNotificationsCount,
    handleUnreadMessageCount,
    handleStripeKey,

    handleJSCode,
    handleCSSCode,
    handleFeedSetting,
    // socket instant
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,

    clientMetaData,
  };

  console.log(userInfo, "--userInfo--");
  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
export default ContentSettingState;
